import React from "react"
import Product from "../dynamic-components/product/product"
import PageTitle from "../dynamic-components/page-title"
import RecipeTeaser from "../dynamic-components/recipe-teaser"

const CollectionStructureComponents = ({ data, pageContext }) => {
  const displayData = pageContext.collectionSlugs.map(slug => {
    return data.allSanityProduct.nodes
      .map(product => {
        if (product.product_collection.slug.current === slug) {
          return product
        } else {
          return null
        }
      })
      .filter(item => item !== null)
  })

  const content = displayData.map((arr, i) => {
    const withRecipe = []
    let j = 0
    while (j < arr.length) {
      withRecipe.push(arr[j])
      if (j === 1 || (j === arr.length - 1 && arr.length < 2)) {
        if (data.allSanityCollection.nodes[i].featured_recipe) {
          withRecipe.push(data.allSanityCollection.nodes[i].featured_recipe)
        }
      }
      j++
    }
    return withRecipe
  })

  return (
    <>
      <PageTitle
        page_title_header={data.sanityShopSiteStructure.field_title}
        page_title_intro={data.sanityShopSiteStructure.intro_text}
      />

      {content.map((arr, i) => (
        <section
          key={`structure__${i}`}
          className="section has-padding-bottom-0"
        >
          <div className="container">
            {arr.length > 0 && (
              <div className="columns">
                <div className="column">
                  <h2
                    className="h4 has-text-centered"
                    style={{ marginBottom: "2rem" }}
                  >
                    {arr[0].product_collection.collection_title}
                  </h2>
                </div>
              </div>
            )}
            <div
              className="columns is-mobile is-multiline"
              style={{ marginBottom: "30px" }}
            >
              {arr.map((item, i) => {
                if (item.hasOwnProperty("recipe_title")) {
                  return (
                    <div
                      key={`${item.recipe_title}_${i}`}
                      className="column is-full-mobile is-half-tablet"
                    >
                      <RecipeTeaser
                        slug={item.slug.current}
                        asset={item.recipe_image_image}
                        title={item.recipe_title}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div
                      key={item._id}
                      className="column is-half-mobile is-one-quarter-tablet"
                    >
                      {<Product {...item} collection_page />}
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </section>
      ))}
    </>
  )
}

export default CollectionStructureComponents
